import type { GatsbyBrowser } from 'gatsby';
import 'whatwg-fetch';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps,
}) => {
  const prevPathname = prevRouterProps ? prevRouterProps.location.pathname : '';
  const currPathname = routerProps ? routerProps.location.pathname : '';

  if (
    prevPathname.indexOf('/login/') >= 0 &&
    (currPathname.endsWith('/login/') ||
      currPathname.indexOf('/login/register-') >= 0)
  ) {
    return false;
  }

  const cockpitPath = '/service/cockpit/';
  if (currPathname.includes(cockpitPath)) {
    if (!prevPathname.includes(cockpitPath)) {
      return true;
    }

    const furnace = (pathname) => {
      const pathAfterCockpit = pathname.split(cockpitPath).pop();
      const [furnace] = pathAfterCockpit.split('/');
      return furnace;
    };
    const prevFurnace = furnace(prevPathname);
    const currFurnace = furnace(currPathname);
    return prevFurnace && currFurnace && currFurnace !== prevFurnace;
  }

  const pathsWithoutScrollToTop = ['/service/database', '/service/media'];
  if (pathsWithoutScrollToTop.some((p) => currPathname.includes(p))) {
    return false;
  }

  return true;
};

export const onRouteUpdateDelayed: GatsbyBrowser['onRouteUpdateDelayed'] = () => {
  window.dispatchEvent(new CustomEvent('routeDelayed'));
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  window.dispatchEvent(new CustomEvent('routeDone'));
};
